import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./analyticsStatsTile.scss";

interface IAnalyticsStatsTileProps {
    icon: IconProp;
    label: React.ReactNode;
    value: React.ReactNode;
}

export const AnalyticsStatsTile = (props: IAnalyticsStatsTileProps) => {
    return (
        <div className="analytics-stats-tile">
            <FontAwesomeIcon icon={props.icon} className="analytics-stats-tile__icon" />
            <div>
                <div className="analytics-stats-tile__label">{props.label}</div>
                <div className="analytics-stats-tile__value">{props.value}</div>
            </div>
        </div>
    );
};
